import Vue, { VNode } from 'vue';

import DashboardMenu from '../DashboardMenu/DashboardMenu';

import './DashboardLayout.css';
import Login from '@/components/Shared/Login/Login';

const DashboardLayout = Vue.extend({
  name: 'dashboardLayout',

  render(): VNode {
    if (!this.$store.state.user) {
      return (
        <div class="login-container">
          <Login />
        </div>
      );
    }

    return (
      <div class="dashboardLayout">
        <DashboardMenu />

        <main class="main">
          <transition>
            <router-view></router-view>
          </transition>
        </main>

      </div>
    );
  },
});

export default DashboardLayout;
