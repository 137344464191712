import Vue from 'vue';

import firebase from '@/services/firebase';

// Images
import MessageIcon from '@/components/Icons/message.png';
import ClientIcon from '@/components/Icons/client.png';
// import TeamIcon from '@/components/Icons/team.png';
// import OrderIcon from '@/components/Icons/orders.png';
import BookingIcon from '@/components/Icons/bookings.png';


import './DashboardMenu.css';

const menuItems = [
  {
    icon: MessageIcon,
    name: 'Dashboard',
    path: '/admin/dashboard',
  },
  {
    icon: ClientIcon,
    name: 'Clients',
    path: '/admin/clients',
  },
  // {
  //   icon: TeamIcon,
  //   name: 'Team',
  //   path: '/admin/team',
  // },
  {
    icon: BookingIcon,
    name: 'Bookings',
    path: '/admin/bookings',
  },
  // {
  //   icon: OrderIcon,
  //   name: 'Orders',
  //   path: '/admin/orders',
  // },
  {
    icon: MessageIcon,
    name: 'Messages',
    path: '/admin/messages',
  },
];

const MenuList = Vue.extend({
  data() {
    return {
      settingsCollapsed: false,
    };
  },

  methods: {
    toggleSettingsList(event: Event) {
      event.preventDefault();
      this.settingsCollapsed = !this.settingsCollapsed;
    },
  },

  name: 'menu-list',

  render() {
    return (
      <ul class="menu__list">
        {
          menuItems.map(item => (
            <li class="menu__item">
              <router-link to={item.path} class="menu__item__link">
                <img src={item.icon} alt={item.name} class="menu__item__icon" />
                <span>
                  {item.name}
                </span>
              </router-link>
            </li>
          ))}

        <li class="menu__item">
          {/* <a class="menu__item__link" onClick={this.toggleSettingsList}> */}
          <a class="menu__item__link">
            <img src={MessageIcon} alt='Settings' class="menu__item__icon" />
            <span>Settings</span>
          </a>

          {
            !this.settingsCollapsed && (
              <ul class="menu__list--nested">
                <li class="menu__item">
                  <router-link to="/admin/settings/slots" class="menu__item__link">
                    <span>Booking Slots</span>
                  </router-link>
                </li>

                <li class="menu__item">
                  <router-link to="/admin/settings/offerings" class="menu__item__link">
                    <span>Packages</span>
                  </router-link>
                </li>

                <li class="menu__item">
                  <router-link to="/admin/settings/users" class="menu__item__link" >
                    <span>Users</span>
                  </router-link>
                </li>
              </ul>
            )
          }

        </li>
      </ul>
    );
  },
});

const DashboardMenu = Vue.extend({
  name: 'dashboard-menu',

  render() {
    return (
      <nav class="menu">
        <router-link to="/admin" class="menu__logo">
          <img
            class="menu__logo__image"
            alt="Teefal Studios logo"
            src="https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663321/Teefal%20Studios/logo.png"
          />
        </router-link>

        <div class="menu__content">
          <MenuList />

          <div class="menu__user">
            <h3>{firebase.auth().currentUser?.displayName}</h3>
            <span>{firebase.auth().currentUser?.email}</span>

            <a
              class="pointer"
              onClick={() => {
                firebase.auth().signOut()
                  .then(() => {
                    this.$router.push('/');
                  });
              }}
            >
              Logout
              </a>
          </div>
        </div>

      </nav>
    );
  },
});

export default DashboardMenu;
