import Vue from 'vue';

import firebase from '@/services/firebase';
import Button from '@/components/Admin/Button/Button';
import ClientNav from '@/components/Clients/ClientNav';

const Login = Vue.extend({
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      formError: '',
      loading: false,
    };
  },

  methods: {
    handleLogin(event: Event) {
      event.preventDefault();
      this.loading = true;

      const { email, password } = this.formData;

      firebase.auth().signInWithEmailAndPassword(email, password)
        .catch((err) => {
          this.formError = err.code === 'auth/wrong-password'
            ? 'Invalid login details'
            : err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  render() {
    return (
      <div>
        <ClientNav />
        <form onSubmit={this.handleLogin} style={{ margin: '50px auto', maxWidth: '90vw', width: '400px' }}>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model={this.formData.email} name="email" required />
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" id="password" v-model={this.formData.password} name="password" required />
          </div>

          {
            this.formError && (
              <div class="form-group">
                <div class="form-error">{this.formError}</div>
              </div>
            )
          }

          <div class="form-group">
            <Button loading={this.loading} primary>Login</Button>
          </div>
        </form>
      </div>

    );
  },
});

export default Login;
